<template>
  <el-dialog :visible.sync="visible" :title="title" :close-on-press-escape="false" :close-on-click-modal="false" width="540px"
    custom-class="v-dialog v-dialog--footer v-dialog--mdpadding" @closed="cancel">
    <slot name="options"></slot>
    <el-upload
      class="upload-demo"
      :multiple="false"
      drag
      :on-remove="() => fileList.splice()"
      :file-list="fileList"
      :on-change="fileChange"
      :on-error="fileError"
      :action="`${POST_URL}?loginKey=${loginInfo.loginKey}&userid=${loginInfo.UserID}&type=excel`">
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">{{$t('batchImport.tip1')}}<em>{{$t('batchImport.tip2')}}</em></div>
      <div class="el-upload__tip" slot="tip">{{$t('batchImport.tip3')}}<a :href="`${DOWNLOAD_URL}docs/${downloadUrl}`" class="i-link">{{$t('batchImport.tip4')}}</a></div>
    </el-upload>
    <div slot="footer">
      <el-button @click="cancel" class="gs-button1" size="medium">{{$t('common.cancel')}}</el-button>
      <el-button @click="confirm" class="gs-button2" :disabled="!filePath" type="primary" size="medium" v-ripple>{{$t('common.confirm')}}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { POST_URL, DOWNLOAD_URL } from '@/api/config'
import { mapGetters } from 'vuex'
import { QueryFullPath } from '@/api/common'
export default {
  props: {
    title: {
      type: String,
      default: '批量更新导入'
    },
    downloadUrl: {
      type: String,
      default: ''
    },
    showTarget: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      resolve: null,
      reject: null,
      POST_URL,
      DOWNLOAD_URL,
      filePath: '',
      fileList: [],
      target: '',
      loading: false
    }
  },
  computed: {
    ...mapGetters([
      'loginInfo',
      'currentHoldId'
    ])
  },
  methods: {
    import() {
      this.visible = true
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    cancel() {
      this.reject(false)
      this.fileList.splice(0)
      this.filePath = ''
      this.visible = false
    },
    confirm() {
      this.resolve(this.filePath)
      this.visible = false
    },
    fileChange(file, fileList) {
      if (fileList.length > 0) {
        this.fileList = fileList.slice(-1)
        this.filePath = fileList[0].response
      }
    },
    fileError() {
      this.$message({
        message: `${this.$t('batchImport.errTip')}`,
        type: 'error'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-demo {
  text-align: center;
  margin-top: 15px;
  & ::v-deep .el-upload-dragger{
    background-color: #2c394a;
    .el-upload__text{
      color:#fff;
    }
  }
}
.batch-import-tip {
  padding: 12px 24px;
  border-radius: 4px;
  // background-color: #F4F4F8;
  color: #FFF;
}
.gs-button1{
  background: url(../../../assets/images/form-btn2.png) no-repeat center;
  background-size: auto 100%;
  color: #2F302C;
  padding: 0;
  border: none;
  height: 42px;
  min-width: 110px;
}
.gs-button1:hover{
  color: #2F302C;
  background: url(../../../assets/images/form-btn2.png) no-repeat center;
  background-size: auto 100%;
}
.gs-button2{
  background: url(../../../assets/images/form-btn1.png) no-repeat center !important;
  background-size: auto 100% !important;
  color: #2F302C !important;
  padding: 0;
  border: none;
  height: 42px;
  min-width: 110px;
}
.gs-button2:hover{
  color: #2F302C;
  background: url(../../../assets/images/form-btn1.png) no-repeat center !important;
  background-size: auto 100% !important;
}
</style>
